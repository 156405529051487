<template>
  <div class="terms-of-services">
    <section class="section hero-tos is-relative is-medium">
      <div class="container has-text-centered has-text-light">
        <h1 class="is-size-1">
          Terms of Services
        </h1>
        <p class="is-size-5">
          Last updated: 11th June, 2023
        </p>
      </div>
    </section>

    <section class="section has-background-light is-medium">
      <div class="container">
        <div class="content">
          <h2 class="title-tos">
            Welcome to Outraverse
          </h2>
          <p>
            Thanks for choosing our services ("Services"). The services are provided BY Outraverse LLC ("Company"), physical located at Colorado, Univted States.
          </p>
          <p>
            These Terms of Service ("Agreement") govern the use of the services provided by the Company in relation to the Web 2.0 and Web 3.0 service support, including web design, web development, and database design Services. By accessing or using our Services, you agree to be bound by the terms and conditions outlined in this Agreement.
          </p>
          <h2 class="title-tos pt-5">
            Acceptance of Terms
          </h2>
          <p>
            By using our Services, you affirm that you are at least 18 years old and have the legal capacity to enter into this Agreement.
          </p>
          <p>
            If you are accessing or using our Services on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that entity to this Agreement, and in such case, "you" and "your" will refer to that entity.
          </p>
          <h2 class="title-tos pt-5">
            Services
          </h2>
          <p>
            Company agrees to provide Services related to web design, web development, and database design, as agreed upon between the Company and the client ("Client").
          </p>
          <p>
            The specific details, scope, and fees for the Services will be outlined in a separate agreement or statement of work.
          </p>
          <h2 class="title-tos pt-5">
            Intellectual Property
          </h2>
          <p>
            The Company retains all rights, title, and interest in any intellectual property or materials created or provided by the Company in connection with the Services, including but not limited to designs, source code, and documentation.
          </p>
          <p>
            Upon full payment of the agreed fees, the Company grants the Client a non-exclusive, non-transferable license to use the deliverables provided as part of the Services solely for the Client's internal business purposes.
          </p>
          <p>
            The Client represents and warrants that it has the necessary rights or permissions to provide any content, materials, or intellectual property to the Company for use in the Services, and the Client grants the Company a non-exclusive, royalty-free license to use such content, materials, or intellectual property as necessary to perform the Services.
          </p>
          <h2 class="title-tos pt-5">
            Confidentiality
          </h2>
          <p>
            Both parties agree to treat any confidential information obtained during the provision of the Services as confidential and not disclose it to any third parties, except as required by law.
          </p>
          <p>
            This confidentiality obligation shall survive the termination of this Agreement.
          </p>
          <h2 class="title-tos pt-5">
            Limitation of Liability
          </h2>
          <p>
            The Company will perform the Services with reasonable care and skill. However, to the maximum extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the Services or this Agreement.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TermsServices",
  created() {
    document.title = "Terms of Services | Outraverse"
  }
}
</script>

<style lang="scss" scope>
.hero-tos {
  background-color: rgba(0, 0, 0, 0.85);
}
.title-tos {
  font-size: 3.75rem
}
</style>